/* =============================================================================
 * Cart mutation types
 * ========================================================================== */

/**
 * Update usere information
 * @type {String}
 */
export const UPDATE_USER = 'UPDATE_USER';

/**
 * User adds an adress
 * @type {String}
 */
export const ADD_ADDRESS = 'ADD_ADDRESS';

/**
 * User deletes an adress
 * @type {String}
 */
export const DELETE_ADDRESS = 'DELETE_ADDRESS';

/**
 * User updates an address
 * @type {String}
 */
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';

/**
 * User is loading
 * @type {String}
 */
export const SET_USER_IS_LOADING = 'SET_USER_IS_LOADING';
