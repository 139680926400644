import { __USER_I18N__ } from 'window';
import { i18n } from 'App';

/** @type {String} Translation domain */
export const domain = 'user';

// Register translations into the app i18n manager
i18n.add(__USER_I18N__, domain);

/**
 * Proxy the traduction to the `App.i18n.l` method
 *
 * @param  {String} key     The key of the translation
 * @param  {Object} replace An object of elements to replace in the translation
 * @return {String}         The formated translated string
 */
export function l(key, replace = null) {
  return i18n.l(key, domain, replace);
}

export default l;
