import { prestashop } from 'window';
import { i18n, components } from 'App';
import globalFetch from '~/utils/fetch';
import { log } from './utils/debug';
import { l } from './utils/i18n';
import * as types from './mutation-types';

/**
 * Default fetch options
 * @type {Object}
 */
const defaultOptions = {
  url: '/',
  dataType: 'json',
  method: 'POST',
  xhrFields: { withCredentials: true },
  data: {
    fc: 'module',
    module: 'meta_endpointsmanager',
    controller: 'endpoints',
    action: 'user',
    method: 'help',
    /* eslint-disable-next-line */
    id_lang: i18n.id,
  },
};

/**
 * Get an adress type fields from an object containing prefixed fields
 *
 * @param  {Object} formData The form data to filter
 * @param  {String} type     The type of the address we want
 * @return {Object}          A formatted address field object
 */
function getAddressFromFormData(formData, type = 'shipping') {
  return Object.keys(formData)
    .filter((key) => key.startsWith(type) || [ 'firstname', 'lastname' ].includes(key))
    .reduce((address, key) => {
      let newKey = key.replace(type, '');
      newKey = newKey.charAt(0).toLowerCase() + newKey.substring(1);
      address[newKey] = formData[key];
      return address;
    }, {});
}

/**
 * Local fetch function mergin custom and local default options
 *
 * @param  {Object}  options The custom options to pass to the function
 * @return {Promise}         A promise resolving on done, rejecting on fail
 */
async function fetch(dataOptions, skipErrors = false) {
  let response = null;

  try {
    response = await globalFetch({
      ...defaultOptions,
      data: {
        ...defaultOptions.data,
        ...dataOptions,
      },
    });
  } catch (err) {
    components.alert.error(l('There was an unexpected error, try again later.'));
    return response;
  }

  if (!response) {
    components.alert.error(l('There was an unexpected error, try again later.'));
    return response;
  }

  if (response.code !== 200 && !skipErrors) {
    if (response.messages.length) {
      components.alert.error(response.messages.join('<br>'), 0);
    }
    return null;
  }

  if (response.result.static_token && prestashop.modules.meta_frontsettings && prestashop.modules.meta_frontsettings.CONFIG) {
    prestashop.modules.meta_frontsettings.CONFIG.STATIC_TOKEN = response.result.static_token;
  }

  if (response.result.token && prestashop.modules.meta_frontsettings) {
    prestashop.modules.meta_frontsettings.CONFIG.TOKEN = response.result.token;
  }

  return response;
}

export default {
  /**
   * Get the current user state from the server
   *
   * @param  {Vuex}   store The current Vuex module instance
   * @return {Object}       The request response object
   */
  async getUser(store) {
    log('action', 'getUser');
    const response = await fetch({ method: 'get' });

    if (!response) {
      return response;
    }

    store.commit(types.UPDATE_USER, response.result.user);
    return response;
  },

  /**
   * Login a user according to a given email and a given password
   *
   * @param  {Vuex}   store            The current Vuex module instance
   * @param  {String} options.email    The email of the user
   * @param  {String} options.password The password of theuser
   * @return {Object}                  The request response object
   */
  async login(store, { email, password }) {
    log('action', 'login', email);
    store.commit(types.SET_USER_IS_LOADING, true);
    const response = await fetch({
      method: 'login',
      email,
      passwd: password,
    });

    if (!response) {
      store.commit(types.SET_USER_IS_LOADING, false);
      return response;
    }

    store.commit(types.UPDATE_USER, response.result.user);

    // Update shipping and billing address on login
    const { defaultAddress } = store.getters;
    if (defaultAddress) {
      const id = defaultAddress.id_address || defaultAddress.id;
      await store.dispatch('cart/updateBillingAddress', id, { root: true });
      await store.dispatch('cart/updateShippingAddress', id, { root: true });
    }

    store.commit(types.SET_USER_IS_LOADING, false);
    return response;
  },

  /**
   * Logout the curren logged in user
   *
   * @param  {Vuex} store The current Vuex module instance
   * @return {void}
   */
  async logout(store) {
    log('action', 'logout');
    const response = await fetch({
      method: 'logout',
    });

    store.commit(types.UPDATE_USER, response.result.user);
    await store.dispatch('cart/getCart', null, { root: true });
    return response;
  },

  /**
   * Logout the curren logged in user
   *
   * @param  {Vuex} store The current Vuex module instance
   * @return {void}
   */
  async create(store, user) {
    log('action', 'create', user);
    store.commit(types.SET_USER_IS_LOADING, true);

    user.tel = user.shippingTel || '';
    user.mobile = user.shippingMobile || '';

    const response = await fetch(
      {
        method: 'create',
        ...user,
      },
      true,
    );

    if (!response) {
      store.commit(types.SET_USER_IS_LOADING, false);
      return response;
    }

    const { result } = response;
    if (result.errors) {
      const errors = Array.isArray(result.errors)
        ? result.errors
        : Object.keys(result.errors).map((key) => result.errors[key]);
      components.alert.error(errors.join('<br>'));
      store.commit(types.SET_USER_IS_LOADING, false);
      return response;
    }

    // Save shipping address
    const shippingAddress = getAddressFromFormData(user, 'shipping');
    const shippingAddressResponse = await store.dispatch('addAddress', shippingAddress);

    if (!shippingAddressResponse) {
      store.commit(types.SET_USER_IS_LOADING, false);
      return shippingAddressResponse;
    }

    const shippingAddressId = shippingAddressResponse.result.address.id_address
      || shippingAddressResponse.result.address.id;
    await store.dispatch('cart/updateShippingAddress', shippingAddressId, {
      root: true,
    });

    // The following condition goes as follow:
    // 1. If the user is a guest, we always create a separate billing address
    // so it can be edited separately from the shipping address late
    // 2. If the user is not a guest and the billing address is different, we
    // get the billing address informations and create a new address
    // 3. If the user is not a guest and the billing address is the same as the
    // shipping address, we set the billing address with the shipping address id
    if (!user.create || user.billingAddressIsDifferent) {
      // If the user is a guest and billing address is not different, we want
      // to get the address data from the shipping form data, otherwise we can
      // get the address data from the billing form data.
      const type = !user.create && !user.billingAddressIsDifferent ? 'shipping' : 'billing';
      const billingAddress = getAddressFromFormData(user, type);
      const billingAddressResponse = await store.dispatch('addAddress', billingAddress);

      if (!billingAddressResponse) {
        store.commit(types.SET_USER_IS_LOADING, false);
        return billingAddressResponse;
      }

      const billingAddressId = billingAddressResponse.result.address.id_address
        || billingAddressResponse.result.address.id;

      await store.dispatch('cart/updateBillingAddress', billingAddressId, {
        root: true,
      });
    } else {
      await store.dispatch('cart/updateBillingAddress', shippingAddressId, {
        root: true,
      });
    }

    // Finally, refetch user to get a proper state object
    await store.dispatch('getUser');
    store.commit(types.SET_USER_IS_LOADING, false);
    return response;
  },

  /**
   * Update the given address
   *
   * @param  {Vuex}   store   The current Vuex module instance
   * @param  {Object} address The address object
   * @return {Object}         The request's response
   */
  async updateAddress(store, { id, address }) {
    log('action', 'updateAddress', id, address);
    const response = await fetch({
      action: 'address',
      method: 'updateAddress',
      /* eslint-disable-next-line camelcase */
      id_address: id,
      /* eslint-disable-next-line camelcase */
      address_fields: address,
    });

    if (!response) {
      return response;
    }

    store.commit(types.UPDATE_ADDRESS, response.result.address);
    await store.dispatch('cart/getCart', null, { root: true });
    return response;
  },

  /**
   * Update the given address
   *
   * @param  {Vuex}   store   The current Vuex module instance
   * @param  {Object} address The address object
   * @return {void}
   */
  async addAddress(store, address) {
    log('action', 'addAddress', address);
    /* eslint-disable-next-line camelcase */
    const { id_address, ...address_fields } = address;

    const response = await fetch({
      action: 'address',
      method: 'addAddress',
      /* eslint-disable-next-line camelcase */
      address_fields,
    });

    if (!response) {
      return response;
    }

    store.commit(types.ADD_ADDRESS, response.result.address);
    await store.dispatch('cart/getCart', null, { root: true });
    return response;
  },

  /**
   * Update the customer
   *
   * @param  {Vuex}   store     The current Vuex module instance
   * @param  {Object} customer  The customer object
   * return  {void}
   */
  async update(store, customerFields) {
    log('action', customerFields);

    const response = await fetch({
      action: 'user',
      method: 'update',
      /* eslint-disable-next-line camelcase */
      id_customer: store.state.id,
      /* eslint-disable-next-line camelcase */
      customer_fields: customerFields,
    });

    log('response', response);

    if (!response) {
      return response;
    }

    store.commit(types.UPDATE_USER, response.result.user);
    return response;
  },
};
