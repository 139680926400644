import Vue from 'vue';
import * as types from './mutation-types';
import { log } from './utils/debug';

export default {
  /**
   * Login a given user
   *
   * @param  {Object} state   The current store's state
   * @param  {Object} user    The user to login
   * @return {void}
   */
  [types.UPDATE_USER](state, user) {
    log('mutation', types.UPDATE_USER, user);

    return Object.entries(user).reduce((oldState, [ key, value ]) => {
      if (key in oldState && oldState[key] !== value) {
        oldState[key] = value;
      }
      return oldState;
    }, state);
  },

  /**
   * Add the given address to the current user
   *
   * @param  {Object} state The current store's state
   * @return {void}
   */
  [types.ADD_ADDRESS](state, address) {
    log('mutation', types.ADD_ADDRESS, address);
    Vue.set(state.addresses, address.id, address);
  },

  /**
   * Delete the given address from the current user
   *
   * @param  {Object} state The current store's state
   * @return {void}
   */
  [types.DELETE_ADDRESS](state, address) {
    log('mutation', types.DELETE_ADDRESS, address);

    if (state.addresses.has(address.id)) {
      state.address.delete(address.id);
    }
  },

  /**
   * Update the given address from the current user
   *
   * @param  {Object} state The current store's state
   * @return {void}
   */
  [types.UPDATE_ADDRESS](state, address) {
    log('mutation', types.UPDATE_ADDRESS, address);

    const { id } = address;

    if (!(id in state.addresses)) {
      return;
    }

    const oldAddress = state.addresses[id];
    Object.keys(address).forEach((key) => {
      // Do not update unexisting keys nor unchanged values
      if (!(key in oldAddress) || oldAddress[key] === address[key]) {
        return;
      }

      state.addresses[id][key] = address[key];
    });
  },

  /**
   * Set the user loading status
   *
   * @param  {Object}  state     The current store's state
   * @param  {Boolean} isLoading Is it loading.
   * @return {Object}            The new state
   */
  [types.SET_USER_IS_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
};
